<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    :mini-variant="$store.state.mini"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :right="$vuetify.rtl"
    :src="require(`@/my/assets/backgrounds/` + barImage)"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template #img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <span class="logo-mini">
            <v-img
              v-if="$store.state.mini"
              :src="require(`@/my/assets/favicon.png`)"
              contain
              position="center bottom"
              height="36"
            />
            <span v-else>{{ $tc("my", 1) }}</span>
          </span>
          <span class="logo-normal">
            <v-img
              v-if="!$store.state.mini"
              :src="require(`@/my/assets/logo.png`)"
              contain
              position="left top"
              height="36"
            />
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <base-item-group
        :item="profile"
      />
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        /* {
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/portal/dashboard',
        }, */
        {
          icon: 'mdi-domain',
          title: 'company',
          to: '/portal/company',
        },
        {
          icon: 'mdi-account-multiple',
          title: 'user',
          count: 2,
          to: '/portal/users',
          auth: ['customer/user/index'],
        },
        {
          icon: 'mdi-playlist-music',
          title: 'subscription',
          count: 2,
          to: '/portal/subscriptions',
          auth: ['customer/subscription/index'],
        },
        {
          icon: 'mdi-cloud-upload',
          title: 'music-upload',
          count: 2,
          to: '/portal/musicuploads',
          auth: ['customer/cloud/index'],
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          // avatar: true,
          group: '/portal',
          icon: 'mdi-account',
          title: this.$auth.user().name,
          children: [
            {
              to: 'profile',
              icon: 'mdi-card-account-details',
              title: this.$tc('profile', 1),
            },
            {
              to: 'logout',
              icon: 'mdi-logout',
              title: this.$tc('logout', 1),
            },
          ],
        }
      },
    },

    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        // this.$emit('update:expandOnHover', !val)
      },
    },

    methods: {
      mapItem (item) {
        if (!item.count) item.count = 1
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$tc(item.title, item.count),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
